import { IEmployeeOrgHierarchy } from '../models/Employee';

export default class Utils {
    public static getPreferredName(profile: IEmployeeOrgHierarchy, lastNameFirst = true): string {
        const lastName = profile.preferredLastName ? profile.preferredLastName : profile.lastName;
        const firstName = profile.preferredFirstName ? profile.preferredFirstName : profile.firstName;

        if (lastNameFirst) {
            return `${lastName}, ${firstName}`;
        }

        return `${firstName} ${lastName}`;
    }

    public static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
