import * as React from 'react';
import { Spinner, Stack } from "@fluentui/react";
import './loading.scss';
class LoadingScreen extends React.Component {
  public render() {
    return (
      <Stack verticalFill={true} verticalAlign="center" horizontalAlign="center">
        <div className="loading-screen">
          <Spinner label="Authorizing..." ariaLive="assertive" labelPosition="bottom" />
        </div>
      </Stack>
    );
  }
}

export default LoadingScreen;