export const ActionType = {
    LOGIN: 'LOGIN',
    SSALOGIN: 'SSALOGIN',
    LOGOUT: 'LOGOUT',
    SET_PERMISSIONS: 'SET_PERMISSIONS',
    SWITCH_WOB: 'SWITCH_WOB',
    SWITCH_APP: 'SWITCH_APP',
    RESET: 'RESET',
    CHANGE_POOL_OWNER: 'CHANGE_POOL_OWNER',
    SHOW_ERROR: 'SHOW_ERROR',
    HIDE_ERROR: 'HIDE_ERROR',
    SHOW_OVERLAY: 'SHOW_OVERLAY',
    HIDE_OVERLAY: 'HIDE_OVERLAY',
    SET_DIALOG: 'SET_DIALOG',
    SET_PROGRAM_INSTANCE: 'SET_PROGRAM_INSTANCE',
    SET_PROGRAM_INSTANCES: 'SET_PROGRAM_INSTANCES'
};
