import * as React from 'react';
import {
    IPersonaProps,
    IBasePickerSuggestionsProps,
    NormalPeoplePicker,
    IBasePickerStyles,
    IBasePickerStyleProps,
    PeoplePickerItemSuggestion,
} from '@fluentui/react';
import { IStyleFunction } from '@uifabric/utilities';
import { EmployeeDataService } from '../../common/services/EmployeeDataService';

interface IPeopleSearchProps {
    onSelect(items: any[], currentValues?: number[] | string[]): void;
    items?: any[];
    maxItems?: number;
    perners?: number[];
    placeholder?: string;
    className?: string;
    styles?: IStyleFunction<IBasePickerStyleProps, IBasePickerStyles> | Partial<IBasePickerStyles> | undefined;
    employeeDataService: EmployeeDataService;
    programInstanceID: number;
}

interface IPeopleSearchState {
    peopleList: IPersonaProps[];
    mostRecentlyUsed: IPersonaProps[];
}

const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading...',
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
    resultsMaximumNumber: parseInt(REACT_APP_LIMIT_PEOPLE_RESULTS, 10),
    searchingText: 'Searching...',
};

export class PeopleSearch extends React.Component<IPeopleSearchProps, IPeopleSearchState> {
    private controller = new AbortController();

    constructor(props: IPeopleSearchProps) {
        super(props);

        this.state = {
            peopleList: [],
            mostRecentlyUsed: [],
        };
    }

    public onRenderSuggestionsItem = (personaProps: IPersonaProps, suggestionsProps: any) => (
        <PeoplePickerItemSuggestion
            personaProps={personaProps}
            suggestionsProps={suggestionsProps}
            styles={{ personaWrapper: { width: 'auto' } }}
        />
    );

    public render() {
        return (
            <NormalPeoplePicker
                onRenderSuggestionsItem={this.onRenderSuggestionsItem}
                onResolveSuggestions={this.onFilterChangedWithLimit}
                getTextFromItem={this.getTextFromItem}
                pickerSuggestionsProps={suggestionProps}
                className={'ms-PeoplePicker ' + this.props.className}
                key={'Normal'}
                removeButtonAriaLabel={'Remove'}
                onRemoveSuggestion={this.onRemoveSuggestion}
                resolveDelay={300}
                inputProps={{
                    placeholder: this.props.placeholder || 'search organization',
                    'aria-label': this.props.placeholder || 'search organization',
                }}
                itemLimit={this.props.maxItems}
                onChange={this.onItemsChange}
                selectedItems={this.props.items}
                styles={this.props.styles}
            />
        );
    }

    public componentWillUnmount() {
        this.controller.abort();
    }

    private getEmployeesForPeoplePicker = async (searchString: string, noOfEmployees: string) => {
        try {
            if (searchString && searchString.length > 2) {
                const peopleList: IPersonaProps[] = (
                    await this.props.employeeDataService.getEmployeesForPeoplePicker(
                        this.props.programInstanceID.toString(),
                        searchString,
                        noOfEmployees
                    )
                ).map((e) => {
                    return {
                        key: e.personnelNumber,
                        text: `${e.lastName}, ${e.firstName}`,
                        secondaryText: e.emailName,
                        tertiaryText: e.personnelNumber.toString(),
                        onRenderSecondaryText: this.renderSecondaryText,
                    };
                });

                this.setState({
                    peopleList: peopleList.sort((a, b) =>
                        a.key < b.key ? -1 : a.key > b.key ? 1 : 0
                    )
                });
            }
        } catch (e) { }

        return this.state.peopleList;
    };

    private getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    private renderSecondaryText = (props: IPersonaProps) => {
        return (
            <span>
                {props.secondaryText} - {props.tertiaryText}
            </span>
        );
    };

    private onFilterChanged = async (filterText: string, currentPersonas: IPersonaProps[], limitResults?: number) => {
        return await this.getEmployeesForPeoplePicker(filterText, limitResults ? limitResults.toString() : '10');
    };

    private onRemoveSuggestion = (item: IPersonaProps): void => {
        const { peopleList, mostRecentlyUsed: mruState } = this.state;
        const indexPeopleList: number = peopleList.indexOf(item);
        const indexMostRecentlyUsed: number = mruState.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople: IPersonaProps[] = peopleList
                .slice(0, indexPeopleList)
                .concat(peopleList.slice(indexPeopleList + 1));
            this.setState({ peopleList: newPeople });
        }

        if (indexMostRecentlyUsed >= 0) {
            const newSuggestedPeople: IPersonaProps[] = mruState
                .slice(0, indexMostRecentlyUsed)
                .concat(mruState.slice(indexMostRecentlyUsed + 1));
            this.setState({ mostRecentlyUsed: newSuggestedPeople });
        }
    };

    private onItemsChange = (items: any[]): void => {
        this.props.onSelect(items, this.props.perners);
    };

    private onFilterChangedWithLimit = async (filterText: string, currentPersonas: IPersonaProps[]) => {
        return await this.onFilterChanged(filterText, currentPersonas, parseInt(REACT_APP_LIMIT_PEOPLE_RESULTS, 10));
    };
}

export default PeopleSearch;
