import * as React from 'react';
import { ErrorInfo } from 'react';
import logService from '../../common/services/LogService';
import { telemetryService } from '../../common/services/TelemetryService';
import { Dialog, DialogType } from "@fluentui/react";

export interface IErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true,
            error,
            errorInfo
        });

        logService.logError('React Render Error', {
            componentStack: errorInfo!.componentStack
        });
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Dialog
                    hidden={!this.state.hasError}
                    onDismiss={this.closeDialog}
                    minWidth="100%"
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: `Oops, something went wrong :(`,
                        subText: `${telemetryService.context &&
                            telemetryService.context.correlationId &&
                            `If you are contacting support, please use this correlation ID: ${telemetryService.context.correlationId.toString()}`}`,
                        styles: {
                            content: {
                                maxWidth: '60%',
                                minWidth: 640,
                                margin: '0 auto',
                                whiteSpace: 'pre-line'
                            }
                        }
                    }}
                    modalProps={{
                        isBlocking: false,
                        isDarkOverlay: true
                    }}
                />
            );
        }
        return this.props.children;
    }

    private closeDialog = (): void => {
        this.setState({
            hasError: false
        });
    };
}

export default ErrorBoundary;
