import { IHttpClient, IHttpClientRequest } from '@employee-experience/common/lib/Models';
import { ISSAContext, IContext } from '../models/Context';
import { IAskHrCaseInput, IAskHrCaseOuput } from '../models/AskHrCase';
import { IProgramInstance, IProgramInstanceResponse } from '../models/ProgramInstance';

export class CommonService {
    private httpClient: IHttpClient;
    private url: string;
    private ssaUrl: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_COMMON_API_URL;
        this.ssaUrl = REACT_APP_SSA_API_URL;
    }

    public async getWobContext(context: IContext): Promise<boolean> {
        const request: IHttpClientRequest = {
            method: 'POST',
            url: `${this.url}UserPermissions/GetWobUserContext`,
            data: JSON.stringify(context)
        };

        return (await this.httpClient.request<boolean>(request))?.data;
    }

    public async canImpersonate(context: IContext): Promise<boolean> {
        const request: IHttpClientRequest = {
            method: 'POST',
            url: `${this.url}UserPermissions/Impersonate`,
            data: JSON.stringify(context)
        };

        return (await this.httpClient.request<boolean>(request))?.data;
    }

    public async getWobUserPermissions(context: IContext): Promise<any> {
        const input = {
            programInstanceID: context.programInstanceID,
            wobAlias: context.wobAlias,
            wobPerner: context.wobPerner,
            loggedInAlias: context.loggedInAlias,
            loggedInPerner: context.loggedInPerner
        };

        const request: IHttpClientRequest = {
            method: 'POST',
            url: `${this.url}UserPermissions/WOBUserPermissionList`,
            data: JSON.stringify(input)
        };

        return (await this.httpClient.request(request))?.data;
    }

    public async getSSAUserPermissions(ssaContext: ISSAContext): Promise<any> {
        const input = {
            programInstanceID: ssaContext.programInstanceID,
            poolOwnerAlias: ssaContext.poolOwnerAlias,
            poolOwnerPerner: ssaContext.poolOwnerPerner,
            loggedInAlias: ssaContext.loggedInAlias,
            loggedInPerner: ssaContext.loggedInPerner
        };

        const request: IHttpClientRequest = {
            method: 'POST',
            url: `${this.ssaUrl}Permissions/UserPermissions`,
            data: JSON.stringify(input)
        };

        return (await this.httpClient.request(request))?.data;
    }

    public async createAskHrCase(input: IAskHrCaseInput): Promise<IAskHrCaseOuput> {
        const request: IHttpClientRequest = {
            method: 'POST',
            url: `${this.url}Common/CreateAskHRCase`,
            data: JSON.stringify(input)
        };

        return (await this.httpClient.request<IAskHrCaseOuput>(request))?.data;
    }

    public async getProgramInstances(): Promise<IProgramInstance[]> {
        const request: IHttpClientRequest = {
            method: 'GET',
            url: `${this.url}Admin/GetAllProgramInstances`
        }

        const result = (await this.httpClient.request<IProgramInstanceResponse>(request))?.data;
        return result.programInstances;
    }

    public async getActiveProgramInstances(): Promise<IProgramInstance[]> {
        const request: IHttpClientRequest = {
            method: 'GET',
            url: `${this.url}Admin/GetActiveProgramInstance`
        }
        const result = (await this.httpClient.request<IProgramInstanceResponse>(request))?.data;
        return result.programInstances;
    }
}
