import { UserContext } from '../../common/models/Context';
import { ActionType } from './actionTypes';
import { AppType } from '../../common/models/AppType';
import { IProgramInstance } from '../models/ProgramInstance';

export const login = (userContext: UserContext) => ({
    data: userContext,
    type: ActionType.LOGIN
});

export const ssalogin = (userContext: UserContext) => ({
    data: userContext,
    type: ActionType.SSALOGIN
});

export const logout = () => ({
    type: ActionType.LOGOUT
});

export const permissions = (userContext: UserContext) => ({
    data: userContext,
    type: ActionType.SET_PERMISSIONS
});

export const switchWob = (userContext: UserContext) => ({
    data: userContext,
    type: ActionType.SWITCH_WOB
});

export const switchApp = (app: AppType) => ({
    data: app,
    type: ActionType.SWITCH_APP
});

export const resetReduxState = () => ({
    type: ActionType.RESET
});

export const changePoolOwner = (userContext: UserContext) => ({
    type: ActionType.CHANGE_POOL_OWNER,
    data: userContext
});

export const showError = (error: string) => ({
    data: error,
    type: ActionType.SHOW_ERROR
});

export const hideError = () => ({
    type: ActionType.HIDE_ERROR
});

export const showOverlay = () => ({
    type: ActionType.SHOW_OVERLAY
});

export const hideOverlay = () => ({
    type: ActionType.HIDE_OVERLAY
});

export const setDialog = (data: number) => ({
    type: ActionType.SET_DIALOG,
    data
})

export const setProgramInstance = (data: IProgramInstance) => ({
    type: ActionType.SET_PROGRAM_INSTANCE,
    data
})

export const setProgramInstances = (data: IProgramInstance[]) => ({
    type: ActionType.SET_PROGRAM_INSTANCES,
    data
})