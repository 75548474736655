import {
    Link,
    Persona,
    Stack,
    MessageBar,
    MessageBarType,
    Dialog,
    PrimaryButton,
    DialogType,
    DefaultButton,
    PersonaSize,
} from '@fluentui/react';
import * as React from 'react';
import { UserContext } from '../../common/models/Context';
import { AppType } from '../../common/models/AppType';
import { match, withRouter } from 'react-router-dom';
import { Action } from '../../common/models/Action';
import Utils from '../../common/util/Utils';
import PeopleSearch from '../Header/PeopleSearch';
import { connect } from 'react-redux';
import {
    login,
    logout,
    switchWob,
    permissions,
    resetReduxState,
    setDialog,
    setProgramInstance,
    setProgramInstances,
} from '../../common/redux/actions';
import { IAppState } from '../../common/redux/AppState';
import { CommonService } from '../../common/services/CommonService';
import { EmployeeDataService } from '../../common/services/EmployeeDataService';
import { Paths } from '../../paths';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { IProgramInstance } from '../../common/models/ProgramInstance';

interface IPersonaSelectorProps {
    userContext: UserContext;
    login: (userContext: UserContext) => { type: string; data: UserContext };
    logout: () => { type: string };
    switchWob: (userContext: UserContext) => { type: string; data: UserContext };
    permissions: (userContext: UserContext) => { type: string; data: UserContext };
    resetReduxState: () => { type: string };
    setDialog: (index: number) => { type: string; data: number };
    setProgramInstance: (pgmInstance: IProgramInstance) => { type: string; data: IProgramInstance };
    setProgramInstances: (pgmInstances: IProgramInstance[]) => { type: string; data: IProgramInstance[] };
    currentApp: AppType;
    history: any;
    location: any;
    match: match;
    commonService: CommonService;
    employeeDataService: EmployeeDataService;
    showDialog: boolean;
}

interface IPersonaSelectorState {
    hideDialog: boolean;
    selectedUser: {
        perner: number;
        alias: string;
    };
    displayName: string;
    statusMessage?: JSX.Element;
    dropdown: IDropdownOption[];
    selectedPgmInstance: IProgramInstance;
}

const options: IDropdownOption[] = [];

export class PersonaSelector extends React.Component<IPersonaSelectorProps, IPersonaSelectorState> {
    constructor(props: IPersonaSelectorProps) {
        super(props);

        this.state = {
            hideDialog: true,
            selectedUser: {
                perner: 0,
                alias: '',
            },
            displayName: '',
            dropdown: [],
            selectedPgmInstance: {
                programInstanceDescription: '',
                programInstanceID: 0,
                programInstanceName: '',
                startDate: '',
                endDate: '',
                mreInstanceID: 0,
                edsInstanceID: 0,
                inflightPromo: false,
                enableModelRerun: false,
            },
        };
    }

    public async componentDidMount(): Promise<void> {
        const pgmInstances = await this.props.commonService.getActiveProgramInstances();
        const { userContext, setDialog, setProgramInstances } = this.props;
        setDialog(2);
        setProgramInstances(pgmInstances != null ? pgmInstances : []);
        options.length = 0;
        if (pgmInstances && pgmInstances.length) {
            pgmInstances.map((item: any) => {
                options.push({
                    key: item.programInstanceID,
                    text: item.programInstanceName || item.programInstanceID.toString(),
                    data: { ...item },
                });
            });
        }
        this.setState({ dropdown: options });
        const userActions: string[] =
            userContext.authZPermissions && userContext.authZPermissions.userActions
                ? userContext.authZPermissions.userActions
                : [];

        if (
            this.props.currentApp === AppType.SpecialAwards ||
            this.props.currentApp === AppType.None ||
            !(userActions.includes(Action.Impersonate) || userActions.includes(Action.WOB))
        ) {
            setDialog(2);
        }
    }

    public componentDidUpdate(prevProps: IPersonaSelectorProps): void {
        const { userContext } = this.props;
        const userActions: string[] =
            userContext.authZPermissions && userContext.authZPermissions.userActions
                ? userContext.authZPermissions.userActions
                : [];
        if (prevProps.currentApp !== this.props.currentApp) {
            if (this.props.currentApp === AppType.SpecialAwards) {
                this.workAsMyself();
            }
        }
    }

    public render(): JSX.Element {
        const { userContext } = this.props;
        const userActions: string[] =
            userContext.authZPermissions && userContext.authZPermissions.userActions
                ? userContext.authZPermissions.userActions
                : [];

        let ariaLabel = 'Not logged in';
        if (userContext && userContext.profile) {
            ariaLabel = Utils.getPreferredName(userContext.profile.loggedInProfile) + ' Profile';

            if (userContext.context.loggedInAlias !== userContext.context.wobAlias) {
                ariaLabel += ` and Working on behalf of ${Utils.getPreferredName(userContext.profile.wobProfile)}`;
            } else if (userActions.includes(Action.Impersonate) || userActions.includes(Action.WOB)) {
                ariaLabel += ' and Work on behalf';
            }
        }

        return (
            <Stack.Item>
                {this.props.currentApp !== AppType.Unauthorized && (
                    <Link
                        aria-label={ariaLabel}
                        className="light-focus persona-selector"
                        onClick={this.showDialog}
                        disabled={this.props.currentApp !== AppType.TargetedSSA}
                    >
                        <Persona
                            text={
                                userContext && userContext.profile
                                    ? Utils.getPreferredName(userContext.profile.loggedInProfile)
                                    : 'Not logged in'
                            }
                            secondaryText={
                                userContext.context &&
                                userContext.context.loggedInAlias !== userContext.context.wobAlias
                                    ? `Working on behalf of ${Utils.getPreferredName(userContext.profile.wobProfile)}`
                                    : undefined
                            }
                            size={PersonaSize.size40}
                        />

                        {this.props.currentApp === AppType.TargetedSSA && this.props.showDialog && (
                            <Dialog
                                hidden={!this.props.showDialog}
                                onDismiss={this.closeDialog}
                                minWidth="100%"
                                dialogContentProps={{
                                    type: DialogType.normal,
                                    title: 'Work on behalf of someone (HR Only)',
                                    subText: `Once you have received rights to work on behalf of a manager you can search for him or her using a name, alias or personnel number. You will then be able to access that manager’s organization and take action based on the scope of your HR privileges.
                                        NOTE: Before clicking on Search, you will need to wait for the list of names to appear as there may be a slight delay.`,
                                    styles: {
                                        content: {
                                            maxWidth: '60%',
                                            margin: '0 auto',
                                            whiteSpace: 'pre-line',
                                        },
                                        subText: {
                                            height: 160,
                                            overflow: 'auto',
                                        },
                                    },
                                }}
                                modalProps={{
                                    isBlocking: true,
                                    isDarkOverlay: true,
                                    className: 'start-dialog',
                                }}
                            >
                                <Stack horizontal={true} wrap={true} tokens={{ childrenGap: 10 }}>
                                    <Stack.Item>
                                        <Dropdown
                                            onChange={this.selectedPgmInstance}
                                            options={this.state.dropdown}
                                            selectedKey={this.state.selectedPgmInstance.programInstanceID || null}
                                            dropdownWidth="auto"
                                            placeholder="Select a period"
                                            styles={{
                                                dropdown: { font: '15px' },
                                                root: { alignSelf: 'center' },
                                            }}
                                            ariaLabel="Select a period"
                                        />
                                    </Stack.Item>
                                    <Stack.Item grow={2} styles={{ root: { maxWidth: 310 } }}>
                                        <PeopleSearch
                                            employeeDataService={this.props.employeeDataService}
                                            programInstanceID={this.state.selectedPgmInstance.programInstanceID}
                                            onSelect={this.handleSelect}
                                            maxItems={1}
                                            styles={{
                                                root: {
                                                    width: '100%',
                                                },
                                            }}
                                        />
                                    </Stack.Item>

                                    {userActions.includes(Action.Impersonate) && (
                                        <Stack.Item>
                                            <PrimaryButton
                                                onClick={this.impersonate}
                                                disabled={
                                                    !this.state.selectedUser || this.state.selectedUser.perner === 0
                                                }
                                                text="Impersonate"
                                            />
                                        </Stack.Item>
                                    )}
                                    {userActions.includes(Action.WOB) && (
                                        <Stack.Item>
                                            <PrimaryButton
                                                onClick={this.workOnBehalf}
                                                disabled={
                                                    !this.state.selectedUser || this.state.selectedUser.perner === 0
                                                }
                                                text="Work on behalf"
                                            />
                                        </Stack.Item>
                                    )}
                                    <Stack.Item>
                                        <PrimaryButton onClick={this.workAsMyself} text="Work as myself" />
                                    </Stack.Item>
                                    {userContext.context.loggedInAlias !== userContext.context.wobAlias && (
                                        <Stack.Item>
                                            <DefaultButton onClick={this.closeDialog} text="Cancel" />
                                        </Stack.Item>
                                    )}
                                </Stack>
                                {this.state.statusMessage && (
                                    <>
                                        <br />
                                        {this.state.statusMessage}
                                    </>
                                )}
                            </Dialog>
                        )}
                    </Link>
                )}
            </Stack.Item>
        );
    }

    private closeDialog = (): void => {
        this.props.setDialog(0);
    };

    private onBackClick = (): void => {
        this.props.setDialog(1);
    };

    private showDialog = (): void => {
        if (this.props.currentApp === AppType.TargetedSSA) {
            const { userContext } = this.props;
            const userActions: string[] =
                userContext.authZPermissions && userContext.authZPermissions.userActions
                    ? userContext.authZPermissions.userActions
                    : [];
            if (userActions.includes(Action.Impersonate) || userActions.includes(Action.WOB)) {
                this.props.setDialog(2);
            }
        }
    };

    private handleSelect = (items: any[]): void => {
        if (items && items.length > 0) {
            this.setState({
                selectedUser: {
                    perner: items[0].key,
                    alias: items[0].secondaryText || '',
                },
            });
        } else {
            this.setState({
                selectedUser: {
                    perner: 0,
                    alias: '',
                },
            });
        }
    };

    private selectedPgmInstance = async (
        event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption | undefined
    ): Promise<void> => {
        if (item) {
            this.setState({
                selectedPgmInstance: item.data,
            });
            setProgramInstance(this.state.selectedPgmInstance);
        }
    };

    private redirectToHome = (): void => {
        this.props.resetReduxState();
        if (this.props.currentApp === AppType.TargetedSSA) {
            this.props.history.push(Paths.SA);
        } else {
            this.props.history.push(Paths.SSA);
        }
    };

    private impersonate = async (): Promise<void> => {
        const { userContext, login, setDialog, setProgramInstance } = this.props;
        userContext.context.programInstanceID = this.state.selectedPgmInstance.programInstanceID;
        setProgramInstance(this.state.selectedPgmInstance);
        try {
            if (userContext) {
                const canImpersonate = await this.props.commonService.canImpersonate(userContext.context);

                if (canImpersonate) {
                    const newContext = {
                        loggedInAlias: this.state.selectedUser.alias,
                        loggedInPerner: this.state.selectedUser.perner,
                        wobAlias: this.state.selectedUser.alias,
                        wobPerner: this.state.selectedUser.perner,
                        programInstanceID: userContext.context.programInstanceID,
                    };

                    userContext.context = newContext;
                    userContext.authZPermissions = await this.props.commonService.getWobUserPermissions(
                        userContext.context
                    );

                    const profile = await this.props.employeeDataService.getShortProfileByPernrs([
                        this.state.selectedUser.perner,
                    ]);

                    if (profile.length > 0 && profile[0]) {
                        this.props.userContext.profile = {
                            loggedInProfile: profile[0],
                            wobProfile: profile[0],
                        };
                    }

                    this.setState({
                        selectedUser: {
                            perner: 0,
                            alias: '',
                        },
                        hideDialog: true,
                    });

                    setDialog(0);

                    login(userContext);
                    this.redirectToHome();
                } else {
                    this.setState({
                        statusMessage: (
                            <MessageBar
                                onDismiss={this.onDismissMessageBar}
                                messageBarType={MessageBarType.error}
                                dismissButtonAriaLabel="Close"
                            >
                                You do not have permission to impersonate this user.
                            </MessageBar>
                        ),
                    });
                }
            }
        } catch (e) {
            this.setState({
                statusMessage: (
                    <MessageBar
                        onDismiss={this.onDismissMessageBar}
                        messageBarType={MessageBarType.error}
                        dismissButtonAriaLabel="Close"
                    >
                        Unable to authorize.
                    </MessageBar>
                ),
            });
        }
    };

    private workAsMyself = async (): Promise<void> => {
        const { userContext, switchWob, permissions, setProgramInstance } = this.props;
        setProgramInstance(this.state.selectedPgmInstance);
        userContext.context.programInstanceID = this.state.selectedPgmInstance.programInstanceID;
        if (userContext) {
            if (userContext.context.loggedInAlias === userContext.context.wobAlias) {
                this.closeDialog();
                this.redirectToHome();
            } else {
                try {
                    userContext.context = {
                        ...userContext.context,
                        wobAlias: userContext.context.loggedInAlias,
                        wobPerner: userContext.context.loggedInPerner,
                    };

                    userContext.profile = {
                        ...userContext.profile,
                        wobProfile: userContext.profile.loggedInProfile,
                    };

                    userContext.authZPermissions = await this.props.commonService.getWobUserPermissions(
                        userContext.context
                    );

                    this.closeDialog();

                    switchWob(userContext);
                    permissions(userContext);
                    this.redirectToHome();
                } catch {
                    // User is not allowed to wob
                    this.setState({
                        statusMessage: (
                            <MessageBar
                                onDismiss={this.onDismissMessageBar}
                                messageBarType={MessageBarType.error}
                                dismissButtonAriaLabel="Close"
                            >
                                Unable to authorize.
                            </MessageBar>
                        ),
                    });
                }
            }
        }
    };

    private workOnBehalf = async (): Promise<void> => {
        const { userContext, switchWob, permissions, setDialog, setProgramInstance } = this.props;
        setProgramInstance(this.state.selectedPgmInstance);
        userContext.context.programInstanceID = this.state.selectedPgmInstance.programInstanceID;
        if (userContext) {
            try {
                userContext.context = {
                    ...userContext.context,
                    wobAlias: this.state.selectedUser.alias,
                    wobPerner: this.state.selectedUser.perner,
                };

                const wobContextResponse = await this.props.commonService.getWobContext(userContext.context);

                if (wobContextResponse) {
                    const profile = await this.props.employeeDataService.getShortProfileByPernrs([
                        this.state.selectedUser.perner,
                    ]);

                    if (profile.length > 0 && profile[0]) {
                        this.props.userContext.profile = {
                            ...userContext.profile,
                            wobProfile: profile[0],
                        };
                    }

                    userContext.authZPermissions = await this.props.commonService.getWobUserPermissions(
                        userContext.context
                    );

                    this.setState({
                        selectedUser: {
                            perner: 0,
                            alias: '',
                        },
                        hideDialog: true,
                    });
                    setDialog(0);
                    switchWob(userContext);
                    permissions(userContext);
                    this.redirectToHome();
                } else {
                    this.setState({
                        statusMessage: (
                            <MessageBar
                                onDismiss={this.onDismissMessageBar}
                                messageBarType={MessageBarType.error}
                                dismissButtonAriaLabel="Close"
                            >
                                You do not have permission to org search this employee.
                            </MessageBar>
                        ),
                    });
                }
            } catch {
                // User is not allowed to wob
                this.setState({
                    statusMessage: (
                        <MessageBar
                            onDismiss={this.onDismissMessageBar}
                            messageBarType={MessageBarType.error}
                            dismissButtonAriaLabel="Close"
                        >
                            Unable to authorize.
                        </MessageBar>
                    ),
                });
            }
        }

        switchWob(userContext);
    };

    private onDismissMessageBar = (): void => {
        this.setState({
            statusMessage: undefined,
        });
    };
}

const mapStateToProps = (state: IAppState) => ({
    userContext: state.root.userContext,
    currentApp: state.root.currentApp,
    programInstances: state.root.programInstances,
    programInstance: state.root.programInstance,
    showDialog: state.root.dialog === 2,
});

const PersonaSelectorContainer = connect(mapStateToProps, {
    login,
    logout,
    switchWob,
    permissions,
    setProgramInstance,
    setProgramInstances,
    resetReduxState,
    setDialog,
})(PersonaSelector);

export default withRouter(PersonaSelectorContainer);
