import * as React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RouteComponentProvider } from '@employee-experience/common/lib/RouteComponentProvider';
import { Paths } from './paths';
import LoadingScreen from './components/LoadingScreen';
import Error from './components/Error';

export function Routes(props: { defaultPage: string }): React.ReactElement {
    return (
        <Switch>
            <RouteComponentProvider
                path={Paths.SSA}
                config={{
                    script: '/bundles/ssa.js',
                    name: 'SSA'
                }}
                renderPlaceholder={() => <LoadingScreen />}
            />
            <RouteComponentProvider
                path={Paths.SA}
                config={{
                    script: '/bundles/sa.js',
                    name: 'SA'
                }}
                renderPlaceholder={() => <LoadingScreen />}
            />
            <Route exact={true} path={Paths.UnAuthorized} render={props => <Error />} />
            {props.defaultPage && props.defaultPage === Paths.SA && <Redirect to={Paths.SA} />}
            {props.defaultPage && props.defaultPage === Paths.SSA && <Redirect to={Paths.SSA} />}
            {props.defaultPage && props.defaultPage === Paths.UnAuthorized && <Redirect to={Paths.UnAuthorized} />}
        </Switch>
    );
}
