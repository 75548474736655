import * as React from 'react';
import { MessageBar, MessageBarType, Async, ProgressIndicator } from "@fluentui/react";

interface ITimedMessageBarProps {
    children?: React.ReactNode;
    isSuccess?: boolean;
    onDismiss: () => void;
}

interface IAskITimedMessageBarState {
    percentComplete: number;
    children: React.ReactNode;
    paused: boolean;
}

const INTERVAL_DELAY = 100;
const INTERVAL_INCREMENT = 0.01;

export class TimedMessageBar extends React.Component<ITimedMessageBarProps, IAskITimedMessageBarState> {
    private interval: number;
    private async: Async;

    constructor(props: ITimedMessageBarProps) {
        super(props);

        this.state = {
            paused: false,
            percentComplete: 1,
            children: props.children
        };

        this.onDismiss = this.onDismiss.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    public componentWillUnmount(): void {
        if (this.async) {
            if (this.interval) {
                this.async.clearInterval(this.interval);
            }
            this.async.dispose();
        }
    }

    public componentDidUpdate(prevProps: ITimedMessageBarProps, prevState: IAskITimedMessageBarState) {
        if (prevProps.children !== this.props.children && this.props.children) {
            this.setState(
                {
                    percentComplete: 1,
                    children: this.props.children,
                    paused: false
                },
                () => {
                    if (this.async && this.interval) {
                        this.async.clearInterval(this.interval);
                    }
                    if (this.props.isSuccess) {
                        this.startProgress();
                    }
                }
            );
        }
    }

    public componentDidMount() {
        this.async = new Async(this);
        if (this.props.isSuccess) {
            this.startProgress();
        }
    }

    public render() {
        const { percentComplete } = this.state;

        return (
            <>
                {this.state.percentComplete !== 0 && this.state.children && (
                    <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                        <MessageBar
                            className="timed-message-bar"
                            role="status"
                            aria-live="assertive"
                            messageBarType={this.props.isSuccess ? MessageBarType.success : MessageBarType.error}
                            onDismiss={this.onDismiss}
                            dismissButtonAriaLabel="Close"
                            actions={
                                this.props.isSuccess ? (
                                    <ProgressIndicator percentComplete={percentComplete} />
                                ) : (
                                    undefined
                                )
                            }
                        >
                            {this.state.children}
                        </MessageBar>
                    </div>
                )}
            </>
        );
    }

    private onDismiss(): void {
        this.setState({
            percentComplete: 0,
            children: undefined,
            paused: false
        });
        if (this.async && this.interval) {
            this.async.clearInterval(this.interval);
        }
        this.props.onDismiss();
    }

    private onMouseEnter(): void {
        this.setState({
            paused: true
        });
    }

    private onMouseLeave(): void {
        this.setState({
            paused: false
        });
    }

    private startProgress(): void {
        // update progress
        this.interval = this.async.setInterval(() => {
            if (!this.state.paused) {
                const percentComplete = this.state.percentComplete - INTERVAL_INCREMENT;

                if (percentComplete <= 0) {
                    this.onDismiss();
                } else {
                    this.setState({
                        percentComplete
                    });
                }
            }
        }, INTERVAL_DELAY);
    }
}

export default TimedMessageBar;
