import * as React from 'react';
import { Dialog, DialogType } from "@fluentui/react";
class Error extends React.Component {
  public render() {
    return (
      <Dialog
        hidden={false}
        minWidth="100%"
        dialogContentProps={{
          type: DialogType.normal,
          title: "Access Denied",
          subText: "You don't have access to the application at this point of time.",
          styles: {
            content: {
              maxWidth: "60%",
              minWidth: 640,
              margin: "0 auto",
              whiteSpace: "pre-line"
            }
          }
        }}
        modalProps={{
          isBlocking: true,
          isDarkOverlay: true
        }}
      >
      </Dialog>
    );
  }
}

export default Error;
