import { format } from 'util';
import { IHttpClient, IHttpClientRequest } from '@employee-experience/common/lib/Models';
import { IEmployeePickerOutput } from '../models/Employee';

export class EmployeeDataService {
    private httpClient: IHttpClient;
    private tssaUrl: string;
    private ssaUrl: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.tssaUrl = REACT_APP_SA_SERVICES_BASE_URI + 'EmployeeData/';
        this.ssaUrl = REACT_APP_SSA_API_URL + "EmployeeData/";
    }

    public async getUserPersonnelNumber(emailName: string): Promise<any> {
        if(emailName === __SERVICE_ACCOUNT__.split('@',1)[0]){
            return {personnelNumber: __REPLACE_PERNER__, emailName: __REPLACE_USER__};
        }
        const request: IHttpClientRequest = {
            method: 'GET',
            url: format(`${this.ssaUrl}PersonnelNumber/%s/%s`, REACT_APP_SSA_EMPLOYEE_INSTANCE_ID, emailName)
        };

        return (await this.httpClient.request(request))?.data;
    }

    public async getShortProfileByPernrs(perners: number[]): Promise<any> {
        const request: IHttpClientRequest = {
            method: 'POST',
            url: `${this.ssaUrl}ShortProfileByPernrs`,
            data: JSON.stringify({
                personalNumbers: perners,
                instanceId: REACT_APP_SSA_EMPLOYEE_INSTANCE_ID
            })
        };

        return (await this.httpClient.request(request))?.data;
    }

    public async getEmployeesForPeoplePicker(programInstanceID: string, searchString: string, noOfEmployees: string): Promise<IEmployeePickerOutput[]> {
        const input: URLSearchParams = new URLSearchParams({
            instanceId: programInstanceID,
            searchString,
            noOfEmployees
        });

        const request: IHttpClientRequest = {
            method: 'POST',
            url: `${
                this.tssaUrl
            }GetEmployeesForPeoplePicker?instanceId=${programInstanceID}&searchString=${searchString}&noOfEmployees=${20}`,
            data: input
        };

        return (await this.httpClient.request<IEmployeePickerOutput[]>(request))?.data;
    }
}
