import { IPermission } from './Permission';
import { IEmployeeOrgHierarchy } from './Employee';

export interface IContext {
    programInstanceID: number;
    loggedInAlias: string;
    loggedInPerner: number;
    wobAlias: string;
    wobPerner: number;
}

export interface ISSAContext {
    programInstanceID: number;
    loggedInAlias: string;
    loggedInPerner: number;
    poolOwnerAlias: string;
    poolOwnerPerner: number;
}

export class UserContext {
    public authZPermissions?: IPermission;
    public ssaAuthZPermissions?: IPermission;
    public context: IContext;
    public ssaContext: ISSAContext;
    public profile: {
        loggedInProfile: IEmployeeOrgHierarchy;
        wobProfile: IEmployeeOrgHierarchy;
    };

    public get isWobOn(): boolean {
        return this.context && !this.context.wobAlias && this.context.wobAlias !== this.context.loggedInAlias;
    }

    public canDoAction = (actionID: string): boolean => {
        return this.authZPermissions && this.authZPermissions.effectiveUserActions
            ? this.authZPermissions.effectiveUserActions.includes(actionID)
            : false;
    };

    public hasAnyPermissions = (): boolean => {
        return this.authZPermissions && this.authZPermissions.effectiveUserActions
            ? this.authZPermissions.effectiveUserActions.length > 0
            : false;
    };
}
