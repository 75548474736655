import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import Utils from '../util/Utils';

class TelemetryService {
    private reactPlugin: ReactPlugin;
    private appInsights: ApplicationInsights;
    private contextProps: { [key: string]: any };

    constructor() {
        this.reactPlugin = new ReactPlugin();
        this.contextProps = {};
    }

    public Initialize(history?: History): void {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATION_KEY || '',
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: { history }
                },
                disableCorrelationHeaders: false,
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                isCookieUseDisabled: true // By default App Insights shouldn't use cookies until the user has granted consent - check CookieConsent control
            }
        });
        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(event => {
            this.customDimensionsInitializer(event, this.getContext());
        });
        this.resetCorrelationID();
    }

    public get reactAiPlugin(): ReactPlugin {
        return this.reactPlugin;
    }

    public get ai(): ApplicationInsights {
        return this.appInsights;
    }

    public get context() {
        return this.contextProps || {};
    }

    public getContext(): { [key: string]: any } {
        return this.contextProps || {};
    }

    public setContext(properties: { [key: string]: any }, clearPrevious?: boolean): void {
        if (clearPrevious) {
            this.contextProps = {};
        }
        properties = properties || {};
        for (const key in properties) {
            if (properties.hasOwnProperty(key)) {
                this.contextProps[key] = properties[key];
            }
        }
    }

    public resetCorrelationID() {
        this.context.correlationId = Utils.newGuid();
    }

    private customDimensionsInitializer(envelope: ITelemetryItem, props: { [key: string]: any }): boolean | void {
        envelope.baseData = envelope.baseData || {};
        envelope.baseData.properties = envelope.baseData.properties || {};
        const properties = envelope.baseData.properties;
        for (const key in props) {
            if (props.hasOwnProperty(key)) {
                properties[key] = props[key];
            }
        }
    }
}

export const telemetryService = new TelemetryService();
