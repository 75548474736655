import { UserContext } from '../models/Context';
import { ActionType } from './actionTypes';
import { combineReducers } from 'redux';
import { AppType } from '../models/AppType';
import { IRootState } from './AppState';
import { IDropdownOption } from 'office-ui-fabric-react';
import { IProgramInstance } from '../models/ProgramInstance';

const initialUserContext = new UserContext();

const userContext = (state = initialUserContext, action: { type: string; data?: UserContext }) => {
    switch (action.type) {
        case ActionType.SET_PROGRAM_INSTANCE:
            return Object.assign(
                {},
                {
                    ...state,
                    context: {
                        ...state.context,
                        programInstanceID: (action.data as any).programInstanceID
                    }
                }
            );
        case ActionType.LOGIN:
            return Object.assign(
                {},
                {
                    ...state,
                    authZPermissions: Object.assign({}, action.data!.authZPermissions),
                    context: Object.assign({}, action.data!.context),
                    profile: Object.assign({}, action.data!.profile),
                    ssaAuthZPermissions: Object.assign({}, action.data!.ssaAuthZPermissions),
                    ssaContext: Object.assign({}, action.data!.ssaContext),
                }
            );
        case ActionType.SSALOGIN:
            return Object.assign(
                {},
                {
                    ...state,
                    ssaAuthZPermissions: Object.assign({}, action.data!.ssaAuthZPermissions),
                    ssaContext: Object.assign({}, action.data!.ssaContext),
                }
            );
        case ActionType.LOGOUT:
            return Object.assign(
                {},
                {
                    ...state,
                    authZPermissions: {},
                    ssaAuthZPermissions: {},
                    context: {},
                    ssaContext: {},
                    profile: {},
                }
            );
        case ActionType.SET_PERMISSIONS:
            return Object.assign(
                {},
                {
                    ...state,
                    authZPermissions: Object.assign({}, action.data!.authZPermissions),
                    ssaAuthZPermissions: Object.assign({}, action.data!.ssaAuthZPermissions),
                }
            );
        case ActionType.SWITCH_WOB:
            return Object.assign(
                {},
                {
                    ...state,
                    context: {
                        ...state.context,
                        wobAlias: action.data!.context.wobAlias,
                        wobPerner: action.data!.context.wobPerner,
                    },
                }
            );
        case ActionType.CHANGE_POOL_OWNER:
            return Object.assign(
                {},
                {
                    ...state,
                    ssaAuthZPermissions: Object.assign({}, action.data!.ssaAuthZPermissions),
                    ssaContext: Object.assign({}, action.data!.ssaContext),
                }
            );
        default:
            return state;
    }
};

const initialApp = AppType.None;
const currentApp = (state = initialApp, action: { type: string; data: AppType }) => {
    switch (action.type) {
        case ActionType.SWITCH_APP:
            return action.data;
        default:
            return state;
    }
};

const initialError = '';
const error = (state = initialError, action: { type: string; data: string }) => {
    switch (action.type) {
        case ActionType.SHOW_ERROR:
            return action.data;
        case ActionType.HIDE_ERROR:
            return null;
        default:
            return state;
    }
};

const initialIsLoading = 0;
const isLoading = (state = initialIsLoading, action: { type: string; data: string }) => {
    switch (action.type) {
        case ActionType.SHOW_OVERLAY:
            return state + 1;
        case ActionType.HIDE_OVERLAY:
            return state - 1 < 0 ? 0 : state - 1;
        default:
            return state;
    }
};

const initialDialog = 0;
const dialog = (state = initialDialog, action: { type: string; data: number }) => {
    switch (action.type) {
        case ActionType.SET_DIALOG:
            return action.data;
        default:
            return state;
    }
};

const initialProgramInstance = null;
const programInstance = (state = initialProgramInstance, action: { type: string; data: IProgramInstance }) => {
    switch (action.type) {
        case ActionType.SET_PROGRAM_INSTANCE:
            return action.data;
        default:
            return state;
    }
};

const initialProgramInstances: IProgramInstance[] = [];
const programInstances = (state = initialProgramInstances, action: { type: string; data: IProgramInstance[] }) => {
    switch (action.type) {
        case ActionType.SET_PROGRAM_INSTANCES:
            return action.data;
        default:
            return state;
    }
};

const saPath = '/SA';
const loadSAPath = (state = saPath, action: { type: string; data: string }) => {
    return state;
};

const tssaPath = '/targetedSSA';
const loadTssaPath = (state = tssaPath, action: { type: string; data: string }) => {
    return state;
};

const appReducer = combineReducers({
    currentApp,
    userContext,
    error,
    isLoading,
    saPath: loadSAPath,
    tssaPath: loadTssaPath,
    programInstance,
    programInstances,
    dialog,
});

const rootReducer = (state: IRootState, action: { type: string }) => {
    if (action.type === ActionType.RESET) {
        state = Object.assign(
            {},
            {
                ...state,
                isLoading: initialIsLoading,
                error: initialError,
            }
        );
    }
    return appReducer(state, action);
};

export default rootReducer;
