import { telemetryService } from './TelemetryService';

export class LogService {
    public logInfo = (message: string, additionalProperties?: { [key: string]: any }) => {
        if (!telemetryService.ai) {
        } else {
            telemetryService.ai.trackTrace({ message }, additionalProperties);
        }
    };

    public logError = (message: string, additionalProperties?: { [key: string]: any }) => {
        if (!telemetryService.ai) {
        } else {
            telemetryService.ai.trackException({ error: new Error(message), properties: additionalProperties });
        }
    };

    public logEvent = (name: string, additionalProperties?: { [key: string]: any }) => {
        if (!telemetryService.ai) {
        } else {
            telemetryService.ai.trackEvent({ name }, additionalProperties);
        }
    };

    public logMetric = (
        name: string,
        average: number,
        min?: number,
        max?: number,
        sampleCount?: number,
        additionalProperties?: { [key: string]: any }
    ) => {
        if (!telemetryService.ai) {
        } else {
            telemetryService.ai.trackMetric({ name, average, min, max, sampleCount }, additionalProperties);
        }
    };
}

const logService = new LogService();
export default logService;
