import * as React from 'react';
import './Header.scss';
import { IconButton, Panel, PanelType, Stack, StackItem, Icon, Link } from "@fluentui/react";
import { AppType } from '../../common/models/AppType';
import { switchApp } from '../../common/redux/actions';
import { IAppState } from '../../common/redux/AppState';
import AskHRContainer from '../AskHR';
import { Paths } from '../../paths';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { useHistory } from 'react-router-dom';
import PersonaSelectorContainer from '../../components/PersonaSelector/index';
import { CommonService } from '../../common/services/CommonService';
import { EmployeeDataService } from '../../common/services/EmployeeDataService';
import { ProgramInstanceSelector } from '../ProgramInstanceSelector';
import { PeriodSelector } from '../PeriodSelector';

export function Header(props: {
    commonService: CommonService;
    employeeDataService: EmployeeDataService;
}): React.ReactElement {
    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const [askHR, setAskHR] = React.useState(false);
    const [wafflePanel, setWafflePanel] = React.useState(false);
    const showTSSA = useSelector((state: IAppState) => {
        const authZPermissions = state.root.userContext.authZPermissions;
        return authZPermissions && authZPermissions.userActions && authZPermissions.userActions.length > 0;
    });
    const showSSA = useSelector((state: IAppState) => {
        const authZPermissions = state.root.userContext.ssaAuthZPermissions;
        return authZPermissions && authZPermissions.userActions && authZPermissions.userActions.length > 0;
    });

    const history = useHistory();

    const currentApp = useSelector((state: IAppState) => {
        return state.root.currentApp;
    });

    const showAskHR = React.useCallback(() => {
        setAskHR(true);
    }, []);

    const hideAskHR = React.useCallback(() => {
        setAskHR(false);
    }, []);

    const showPanel = React.useCallback(() => {
        setWafflePanel(true);
    }, []);

    const hidePanel = React.useCallback(() => {
        setWafflePanel(false);
    }, []);

    const onClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        const appName = event.currentTarget.getAttribute('data-app-name');

        switch (appName) {
            case 'TargetedSSA':
                dispatch(switchApp(AppType.TargetedSSA));
                history.push(Paths.SA);
                break;
            case 'Special Awards':
            default:
                dispatch(switchApp(AppType.SpecialAwards));
                history.push(Paths.SSA);
                break;
        }
        hidePanel();
    };

    return (
        <header>
            <Stack
                horizontal={true}
                horizontalAlign="space-between"
                wrap={true}
                className="top-bar"
                styles={{
                    root: {
                        backgroundColor:
                            currentApp === AppType.TargetedSSA
                                ? REACT_APP_ATLANTIS_HEADER_COLOR
                                : REACT_APP_SA_HEADER_COLOR
                    }
                }}
            >
                <Stack.Item align="center" className="headerLeft">
                    <Stack horizontal={true} verticalAlign="center">
                        <IconButton
                            role="button"
                            className={'headerWaffle'}
                            menuIconProps={{ iconName: 'WaffleOffice365', ariaLabel: 'Waffle Menu' }}
                            title="Waffle Menu"
                            onClick={showPanel}
                        />
                        <h1 className="titleLogo large">
                            {currentApp === AppType.TargetedSSA
                                ? 'Targeted Special Stock Awards'
                                : currentApp === AppType.SpecialAwards
                                ? 'Special Awards (SCA/SSA)'
                                : ''}
                        </h1>
                        <h1 className="titleLogo small">
                            {currentApp === AppType.TargetedSSA
                                ? 'TSSA'
                                : currentApp === AppType.SpecialAwards
                                ? 'SA'
                                : ''}
                        </h1>
                    </Stack>
                </Stack.Item>
                <Stack.Item align="center">
                {currentApp === AppType.TargetedSSA && <PeriodSelector />}
                </Stack.Item>
                <Stack.Item align="center">
                    <Stack horizontal={true} verticalAlign="center">
                        {/* have to add link to he right now mi aded to micrsooft.com */}
                        <Link
                            className="light-focus ask-hr-logo"
                            onClick={showAskHR}
                            title="Ask HR"
                            aria-label="Ask HR"
                            styles={{ root: { border: 0, height: '22.17px' } }}
                        >
                            <img src="/assets/AskHRLogo.png" alt="Ask HR logo" />
                        </Link>
                        <PersonaSelectorContainer
                            commonService={props.commonService}
                            employeeDataService={props.employeeDataService}
                        />
                    </Stack>
                </Stack.Item>
            </Stack>
            <AskHRContainer commonService={props.commonService} hidden={!askHR} onDismiss={hideAskHR} />
            <Panel
                isOpen={wafflePanel}
                isLightDismiss={true}
                onDismiss={hidePanel}
                closeButtonAriaLabel = "Close"
                type={PanelType.smallFixedNear}
                className="waffle-panel"
            >
                <h2>Apps</h2>
                <Stack horizontalAlign="start">
                    {showSSA && (
                        <StackItem>
                            <div className="app-box">
                                <Link data-app-name="Special Awards" onClick={onClick}>
                                    <Stack horizontal={true} horizontalAlign="space-between">
                                        <Stack.Item>
                                            <Stack
                                                style={{ width: 48, height: 48 }}
                                                horizontalAlign="center"
                                                verticalAlign="center"
                                            >
                                                <Icon
                                                    iconName="Contact"
                                                    style={{
                                                        fontSize: '20px',
                                                        cursor: 'default'
                                                    }}
                                                />
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item
                                            align="center"
                                            grow={1}
                                            shrink={1}
                                            styles={{ root: { paddingLeft: 6 } }}
                                        >
                                            <div style={{ textAlign: 'left' }}>Special Awards (SCA/SSA)</div>
                                        </Stack.Item>
                                    </Stack>
                                </Link>
                            </div>
                        </StackItem>
                    )}
                    {showTSSA && (
                        <StackItem>
                            <div className="app-box">
                                <Link data-app-name="TargetedSSA" onClick={onClick}>
                                    <Stack horizontal={true} horizontalAlign="space-between">
                                        <Stack.Item>
                                            <Stack
                                                style={{ width: 48, height: 48 }}
                                                horizontalAlign="center"
                                                verticalAlign="center"
                                            >
                                                <Icon
                                                    iconName="Group"
                                                    style={{
                                                        fontSize: '20px',
                                                        cursor: 'default'
                                                    }}
                                                />
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item
                                            align="center"
                                            grow={1}
                                            shrink={1}
                                            styles={{ root: { paddingLeft: 6 } }}
                                        >
                                            <div style={{ textAlign: 'left' }}>Targeted Special Stock Awards</div>
                                        </Stack.Item>
                                    </Stack>
                                </Link>
                            </div>
                        </StackItem>
                    )}
                </Stack>
            </Panel>
        </header>
    );
}
