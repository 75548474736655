import { TelemetryClient } from '@employee-experience/core/lib/TelemetryClient';
import { HttpClient } from '@employee-experience/core/lib/HttpClient';
import { ComponentLoader } from '@employee-experience/core/lib/ComponentLoader';
import { GraphClient } from '@employee-experience/core/lib/GraphClient';
import { Shell } from '@employee-experience/shell';
import { withStore } from '@employee-experience/shell/lib/withStore';
import { StoreBuilder } from '@employee-experience/shell/lib/StoreBuilder';
import { ReducerRegistry } from '@employee-experience/shell/lib/ReducerRegistry';
import rootReducer from './common/redux/reducers';
import { IHttpClientRequest, IHttpClientOption } from '@employee-experience/common/lib/Models';
import { showOverlay, hideOverlay } from './common/redux/actions';
import { MSALV2Client } from '@employee-experience/core/lib/MSALV2Client';


const telemetryClient = new TelemetryClient({
    instrumentationKey: __INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: __ENVIRONMENT_NAME__,
        ServiceOffering: 'Human Resources',
        ServiceLine: 'Rewards and Compensation',
        Service: 'Special Awards',
        ComponentName: 'Special Awards Web',
        ComponentId: 'd26f5c34-6c7d-4be1-a20d-ebc41933792b'
    },
    defaultProperties: {
        appName: __APP_NAME__
    }
});
const authClient = new MSALV2Client(
    {
        auth: {
            clientId: __CLIENT_ID__,
            authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
            redirectUri: window.location.origin
        }
    },
    telemetryClient
);
const httpClient = new HttpClient(telemetryClient, authClient);
const graphClient = new GraphClient(httpClient);
const componentLoader = new ComponentLoader(telemetryClient,httpClient);

// override default request to pass in custom headers
const request = httpClient.request;
httpClient.request = function<T>(_request: IHttpClientRequest, options?: IHttpClientOption): Promise<T> {
    _request = _request || {};
    _request.headers = _request.headers || {};

    if (!_request.resource) {
        _request.resource = __CLIENT_ID__;
    }

    storeResult.store.dispatch(showOverlay());
    return request
        .call<HttpClient, [IHttpClientRequest, IHttpClientOption | undefined], Promise<T>>(
            httpClient,
            _request,
            options
        )
        .finally(() => {
            storeResult.store.dispatch(hideOverlay());
        });
};

const reducerRegistry = new ReducerRegistry();
reducerRegistry.register('root', rootReducer);
export const storeResult = new StoreBuilder(reducerRegistry, {})
    .configureSaga({ telemetryClient, authClient, httpClient, componentLoader, graphClient })
    .configurePersistor({ blacklist: ['root', 'dynamic'] })
    .build();

export const ShellWithStore = withStore(storeResult)(Shell);
